import React from "react"
import Layout from "../../../components/Layout"
import Nav from "../../../components/Nav"
import { AnchorLink } from "gatsby-plugin-anchor-links"

// Images 
import mclarenHero from "../../../images/mclaren-hero.png"
import amazonTabs from "../../../images/amazon-tabs.png"
import apples from "../../../images/tuqa-nabi-71JHj_t-kS0-unsplash.jpg"
// Amazon Mobile versions
import amazonMobile1 from "../../../images/amazon-compare-mobile-1.png"
import amazonMobile2 from "../../../images/amazon-compare-mobile-2.png"
import amazonMobile3 from "../../../images/amazon-compare-mobile-3.png"
import amazonMobile4 from "../../../images/amazon-compare-mobile-4.png"
import amazonMobile5 from "../../../images/amazon-compare-mobile-5.png"
// Amazon Desktop versions
import amazonDesktop1 from "../../../images/amazon-compare-desktop-1.png"
import amazonDesktop2 from "../../../images/amazon-compare-desktop-2.png"

import amazonDesktop4 from "../../../images/amazon-compare-desktop-4.png"
import amazonDesktop5 from "../../../images/amazon-compare-desktop-5.png"

import amazonDesktop7 from "../../../images/amazon-compare-desktop-7.png"
import amazonDesktop8 from "../../../images/amazon-compare-desktop-8.png"
import amazonDesktop9 from "../../../images/amazon-compare-desktop-9.png"
import amazonDesktop10 from "../../../images/amazon-compare-desktop-10.png"
import amazonDesktop11 from "../../../images/amazon-compare-desktop-11.png"
import amazonDesktop12 from "../../../images/amazon-compare-desktop-12.png"
import amazonDesktop13 from "../../../images/amazon-compare-desktop-13.png"
import amazonDesktop14 from "../../../images/amazon-compare-desktop-14.png"
import amazonDesktop15 from "../../../images/amazon-compare-desktop-15.png"
import amazonDesktop16 from "../../../images/amazon-compare-desktop-16.png"
import amazonDesktop17 from "../../../images/amazon-compare-desktop-17.png"

export default function CaseStudy() {

   return (
        <Layout>
            <Nav></Nav>
            <div className="case-study">
                <main>
                    <div className="wrapper">
                        <div className="case-study--hero">
                            <span className="lozenge">Case study</span>
                            <h1>Improving comparison shopping on Amazon.com</h1>
                            <h2>Reducing the friction of purchasing electronic products</h2>
                            <img src={mclarenHero} alt="Comparison shopping hero" />
                        </div>
                        <div className="flex-h">
                            <section className="case-study--background flex-60">
                                <h3>Background</h3>
                                <p>Researchers identified a common behavior of opening multiple tabs in a browser when comparing one kind of product with another. It was not distinct to just amazon.com but to most ecommerce sites and experiences.</p>
                                <blockquote>We asked ourselves, <strong>how might we reduce the friction in the consideration phase of the purchasing journey?</strong></blockquote>
                            </section>
                            <section className="case-study--details flex-40">
                                <div className="flex-h">
                                    <dl>
                                        <dt>Role</dt>
                                        <dd>UX Design</dd>
                                    </dl>
                                    <dl>
                                        <dt>Contribution</dt>
                                        <dd>Prototyping</dd>
                                        <dd>UI design</dd>
                                    </dl>
                                    <dl>
                                        <dt>Collaborators</dt>
                                        <dd>Product Manager</dd>
                                        <dd>Design Technologist</dd>
                                        <dd>UX Designers</dd>
                                    </dl>
                                </div>
                            </section>
                        </div>
                        <section className="case-study--problem">
                            <h3>Problem statement</h3>
                            <p>Amazon.com customers were opening up to 4+ browser tabs during a single session and spending, on average, 18 minutes in a comparison browsing mode before committing to purchase or abandoning the shopping session altogether.</p>
                            <p>A conservative estimate puts this browsing activity at a potential loss of $7.9 million USD in sales during a similar window of time.</p>
                        </section>
                        <section className="case-study--business-metrics">
                            <h3>Business metrics</h3>
                            <ol>
                                <li>Reduction in time from initial comparison shopping to purchase decision.</li>
                                <li>Increase in spending on consumer electronics items on amazon.com</li>
                                <li>Degree of difficulty decreased via sentiment effort score</li>
                            </ol>
                        </section>
                        <section className="case-study--design-process">
                            
                            <div>
                                <h3>Design process</h3>
                                <div className="double-diamond">
                                    <div className="double-diamond-section">
                                        <h3>Discover</h3>
                                        <p>How might we reduce friction in the consideration phase of the purchasing journey?</p>
                                        <ul>
                                            <li>Customer interviews</li>
                                        </ul>
                                    </div>
                                    <div className="double-diamond-section">
                                        <h3>Define</h3>
                                        <p>What's the most opportune moment to remove friction?</p>
                                        <ul>
                                            <li>Qualitative synthesis</li>
                                        </ul>
                                    </div>
                                    <div className="double-diamond-section">
                                        <h3>Develop</h3>
                                        <p>How might we make comparison shopping easier on mobile and desktop?</p>
                                        <ul>
                                            <li>Rapid prototyping</li>
                                            <li>Usability testing</li>
                                        </ul>
                                    </div>
                                    <div className="double-diamond-section">
                                        <h3>Deliver</h3>
                                        <p>Present clear evidence on the design process</p>
                                        <ul>
                                            <li>Final design / Redlines</li>
                                        </ul>
                                    </div>
                                </div>
                                <section id="discovery">
                                    <h4>Discovery phase</h4>
                                    <p><em>Discover insights into the problem by uncovering the problem space</em></p>
                                    <p>As previously mentioned, UX researchers created a study around comparison shopping and watched participants engage in this activity. Participants were pre-screened to be actively looking at a large purchase specifically within the consumer electronics space. These participants were instructed to research and find their specific products.</p>
                                    <p>We had a large set of data with hours of footage to comb through and synthesize.</p>
                                </section>
                                <section id="definition">
                                    <h4>Definition phase</h4>
                                    <p><em>Defining the area to focus efforts</em></p>
                                    <p>After pulling the initial data from the interview sessions, we created an affinity map that pulled together common behaviors. By mapping it all out, we were able to visibly see patterns emerge.</p>
                                    <div className="flex-h">
                                        <ol>
                                            <li>Find products through Google search.</li>
                                            <li>Locate a product of interest on amazon.com.</li>
                                            <li>Search for product on amazon.com through the navigation.</li>
                                            <li>"Command + Click" to open multiple browser tabs of each product of interest from the search results page.</li>
                                            <li>Begin to look through each product detail page.</li>
                                            <li>Once they spotted a specification on the product, they went through each of the open browser tabs to check and verify whether or not the other products had more or less than the interested product page.</li>
                                            <li>Other specifications were compared in this way.</li>
                                            <li>Purchase</li>
                                        </ol>
                                    </div>
                                    <img src={amazonTabs} alt="Multiple open tabs while in comparison shopping mode" style={{marginBottom:`2rem`}}/>
                                    <blockquote>Our team quickly realized that if we combined the efforts of opening multiple tabs with the specification matching activity, then we would be able to reduce the total amount of time spent comparing products as well increase the level of confidence in their search.</blockquote>
                                    <p>By making these observations, and by combining some of these steps together, we can bring total number of steps from eight to five, this would be most ideal for our cusomters.</p>
                                    <ol>
                                        <li>Find products through Google search.</li>
                                        <li>Locate a product of interest on amazon.com.</li>
                                        <li>Search for product on amazon.com through the navigation.</li>
                                        <li>Comparison activity</li>
                                        <li>Purchase</li>
                                    </ol>
                                </section>
                                <section id="develop">
                                    <h4>Develop</h4>
                                    <p><em>Develop potential solutions aka prototyping</em></p>
                                    <div className="flex-h">
                                        <div>
                                            <p>With our definition in hand, we set out to explore different methods of how this would work. Although our UX research primarily occurred on a desktop computer, we didn't want to rule out this kind of activity on a mobile device. We increased our scope for exploration to both a mobile device as well as a desktop computer.</p>
                                            <blockquote>We drew from analog experiences such as the act of picking produce from the grocery store. Comparing one grape to another. </blockquote>
                                            <p>In our initial explorations, we immediately thought about other comparison behaviors outside of the tech world. When picking produce at the grocery store, you pick up a specimen and bring it close. You pick up another one and bring it close. If you're comparison two items, you bring them together. This was the basis of our main interactions.</p>
                                            <p>We first started off with trying to made the specifications easily scannable and keep the density of information small so that we could enable people to do that kind of close up comparison.</p>
                                    
                                            <blockquote>One of the biggest questions in this phase of work became: <strong>Do people want to actively select the products they want to compare or do they want to be given a list of similar items to compare from?</strong></blockquote>
                                        </div>
                                        <div>
                                            <img src={apples} alt="Apples by Tuga Nabi on Unsplash" height="700" /><figcaption>Photo by <cite><a href="https://unsplash.com/@tuqa?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" rel="noreferrer">Tuga Nabi</a> on <a href="https://unsplash.com/s/photos/apples?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" rel="noreferrer">Unsplash</a></cite></figcaption>
                                        </div>
                                    </div>
                                    
                                    <div style={{marginTop:`4rem`}}>
                                        <h4 style={{marginBottom:`1rem`}}>Explorations</h4>
                                        <p>In either case with mobile and desktop, we designed for both experiences and with the interactions of:
                                        </p>
                                        <ol style={{marginBottom:`2rem`}}>
                                            <li>Actively choosing products to compare against and</li>
                                            <li>Affordances to display similar products as a way for comparison shopping.</li>
                                        </ol>
                                        <p><AnchorLink to="/case-studies/amazon/comparison-shopping#desktop">Skip to Desktop explorations…</AnchorLink></p>
                                    </div>

                                    <section>
                                        <h5>Mobile explorations</h5>
                                        <p>Early wireframe of the active comparison interaction</p>
                                        <img src={amazonMobile1} alt="Early sketches" />
                                        <p>A bit more flushed out with more than one product getting pinned.</p>
                                        <img src={amazonMobile2} alt="Wireframes" />
                                        <p>At this point, I went completely divergent in exploration and tried other interactions when it came to comparing specifications.</p>
                                        <p>This one specifically looked at swiping on a row of specifications</p>
                                        <img src={amazonMobile3} alt="Wireframes" />
                                        <p>This exploration tried to capture filtering as a means for comparison</p>
                                        <img src={amazonMobile4} alt="Wireframes" />
                                        <p>This is ultimately what we decided to built a prototype around and go and test users with.</p>
                                        <img src={amazonMobile5} alt="High fidelity mocks" />
                                    </section>

                                    <hr style={{marginBottom:`12rem`, backgroundColor:`#ec5e5e`}} height="2" />

                                    <div id="desktop">
                                        <h5>Desktop explorations</h5>
                                        <div className="flex-h">
                                            <p>It was prudent to start wireframing at a low fidelity to ensure we could talk about the concept rather than get bogged down in the particulars of the UI (typography, color, etc.). At this stage the team wanted to move fast and explore multiple concepts at a time.</p>
                                            <img src={amazonDesktop1} alt="" width="800" />
                                        </div>
                                        <div className="flex-h" style={{marginTop:`4rem`}}>
                                            <img src={amazonDesktop2} alt="" width="1000" />
                                            <div>
                                                <p>After some quick sketches, we quickly realized and agreed that it was important to group the following pieces of information together for comparison sake.</p>
                                                <ul>
                                                    <li>Weight</li>
                                                    <li>Processor speed</li>
                                                    <li>Amount of RAM</li>
                                                    <li>Operating system type</li>
                                                    <li>Physical Screen size</li>
                                                    <li>Screen resolution (in pixels)</li>
                                                </ul>
                                                <p>But it was also important that these compliment the highest priority elements:</p>
                                                <ul>
                                                    <li>Price</li>
                                                    <li>Prime eligibility</li>
                                                    <li>Ratings</li>
                                                    <li>Brand</li>
                                                    <li>Product photo</li>
                                                    <li>Name of the product</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="flex-h" style={{marginTop:`4rem`}}>
                                            <div>
                                                <p>I did some more divergent thinking and exploration and pulled on the thread for visualizing the amount of information we needed to display in clean and clear ways.</p>
                                                <p>These are three different ways we designed the product specifications.</p>
                                            </div>
                                            <img src={amazonDesktop4} alt="" width="800" />
                                        </div>
                                        
                                        <div className="flex-h" style={{marginTop:`4rem`}}>
                                            <img src={amazonDesktop5} alt="" width="800" />
                                            <div>
                                                <p>Through our quick ideation process we were able to determine which layout might serve our customers better - that being the product specifications lined up in a row so that our customers could compare one product to another as they scroll the page.</p>
                                                <p>We then proceeded to design how a customer might be able to take a more active role in their comparison behavior – <strong>pin to compare</strong>.</p>
                                                <p>Basic idea was to enable a customer to pin the product they wanted to compare with other products and it would affix to the top of the screen and then the customer could enter into a comparison mode to see the products selected.</p>
                                            </div>
                                        </div>

                                        <div className="flex-h" style={{marginTop:`4rem`}}>
                                            <div>
                                                <p>With a higher degree of confidence towards the version that we wanted to test with customers, we started to increase the fidelity and matching that with the current amazon.com site.</p>
                                                <p>I explored a tray concept that would help customers know where their pinned products were and how to manage the pinned status of each product.</p>
                                            </div>
                                            <div className="flex-v">
                                                <img src={amazonDesktop7} alt="" width="1000" />
                                                <img src={amazonDesktop8} alt="" width="1000" />
                                                <img src={amazonDesktop9} alt="" width="1000" />
                                                <img src={amazonDesktop10} alt="" width="1000" />
                                                <img src={amazonDesktop11} alt="" width="1000" />
                                                <img src={amazonDesktop12} alt="" width="1000" />
                                                <img src={amazonDesktop13} alt="" width="1000" />
                                                <img src={amazonDesktop14} alt="" width="1000" />
                                            </div>
                                        </div>

                                        <div className="flex-h" style={{marginTop:`4rem`}}>
                                            <img src={amazonDesktop15} alt="" width="1000" />
                                            <p>With the tray at a point where we felt like it was worth exploring, and the product specifications designed with visual clarity, it was time to finesse the design so that it would fit completely within the larger search context of customers. We had to design a harmonious grid for all of this live in.</p>
                                        </div>
                                    </div>
                                    
                                </section>
                                <section id="deliver">
                                    <h4>Deliver</h4>
                                    <p><em>Deliver an experience with confidence</em></p>
                                    <p>We employed usability testing to provide a degree of confidence when it came to our designs. Again, we focused on mobile and desktop experiences and split cohorts accordingly. In setting up the usability testing, we considered two factors:</p>
                                    <ol>
                                        <li>Time to complete a task (compare multiple products)</li>
                                        <li>Degree of difficulty when attempting to completing task</li>
                                    </ol>
                                    <h5>The final spec</h5>
                                    <p>Since we didn't test or benchmark on mobile during initial research, it was difficult to determine the actual decrease in friction, or the change in time on task. All we could do is monitor for sentiment and see if the design was an improvement in that way.</p>
                                    <p>To that end, users reported that although the experience had the potential to be helpful, they weren't going to use their mobile device to do this kind of comparing of products. They preferred to do this kind of activity on desktop instead.</p>
                                    <img src={amazonMobile5} alt="High fidelity mocks" />
                                    <blockquote>In the desktop version, we saw a dramatic decrease in time to task (compare at least two items to one another). In our proposed designs, we saw this time to be 5 minutes on average</blockquote>
                                    <div className="flex-v">
                                        <img src={amazonDesktop16} alt="Text" width="1000" />
                                        <img src={amazonDesktop17} alt="Text" width="1000" />
                                    </div>
                                </section>
                            </div>
                        </section>
                    </div>
                </main>
            </div>
        </Layout>
    )
}